<template>
  <div>
    <b-sidebar
      id="sidebar-new-appointment"
      sidebar-class="sidebar-xg"
      :visible="newAppointmentSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateNewAppointmentSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4 v-if="newAppointmentSidebar.id && newAppointmentSidebar.id > 0">
            Editar Compromisso
          </h4>
          <h4 v-else>Novo compromisso</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row v-if="isLead" class="pb-1" style="color: #f26237"
            ><b-col cols="12">Momento X indisponível para leads</b-col></b-row
          >
          <b-row v-if="isNotLeadPN" class="pb-1" style="color: #f26237"
            ><b-col cols="12"
              >Entrevista PN disponível apenas para leads PNs</b-col
            ></b-row
          >
          <b-row v-if="hasEpRunning" class="pb-1" style="color: #f26237"
            ><b-col cols="12"
              >Cliente ja possui EP</b-col
            ></b-row
          >
          <b-row>
            <b-col cols="12">
              <dynamic-select
                id="appointment-customer"
                label="Cliente"
                placeholder="Digite o nome do cliente/lead"
                v-model="customer"
                :options="customers"
                :loading="loading.customers"
                @find="findCustomers"
                :disabled="isReschedule"
                :class="getSelectErrorClass(v$.customer.$error)"
              />
              <div class="invalid-feedback">
                <span v-if="v$.customer.required.$invalid">
                  Você deve informar um cliente
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!hasEmail">
            <b-col cols="12" class="mt-1">
              <b-form-group
                label="E-mail do cliente"
                label-for="appointment-customer-email"
              >
                <b-form-input
                  id="appointment-customer-email"
                  v-model="customerEmail"
                  type="email"
                  :class="{ 'is-invalid': v$.customerEmail.$error }"
                />
              </b-form-group>
              <div class="invalid-feedback">
                <span v-if="v$.customerEmail.required.$invalid">
                  Você deve informar um email para o cliente
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group
                label="PN responsável"
                label-for="appointment-consultant"
              >
                <v-select
                  id="appointment-consultant"
                  disabled
                  v-model="consultant"
                  :reduce="(consultants_list) => consultants_list.id"
                  :options="consultants"
                  :loading="loading.consultants"
                  label="name"
                  :class="getSelectErrorClass(v$.consultant.$error)"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.consultant.required.$invalid">
                    Você deve informar um PN responsável
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group
                label="PN especialista"
                label-for="appointment-consultant-specialist"
              >
                <v-select
                  id="appointment-consultant-specialist"
                  v-model="consultantSpecialist"
                  :reduce="(consultants_list) => consultants_list.id"
                  :options="specialistConsultants"
                  :loading="loading.specialistConsultants"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group
                label="Tipo de compromisso"
                label-for="appointment-type"
              >
                <v-select
                  id="appointment-type"
                  v-model="eventType"
                  :reduce="(types_list) => types_list.id"
                  :options="eventTypes"
                  :loading="loading.eventTypes"
                  :selectable="disableItem"
                  :disabled="isReschedule"
                  label="description"
                  :class="getSelectErrorClass(v$.eventType.$error)"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.eventType.required.$invalid">
                    Você deve informar o tipo de compromisso
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group
                label="Data de início"
                label-for="appointment-start-date"
              >
                <flat-pickr
                  id="appointment-start-date"
                  v-model="appointmentStart"
                  class="form-control"
                  :config="flatPickrConfig"
                  :class="{
                    'is-invalid': v$.appointmentStart.$error,
                  }"
                  @input="setEndDate"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.appointmentStart.required.$invalid">
                    Você deve informar uma data de início
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group
                label="Data de término"
                label-for="appointment-end-date"
              >
                <flat-pickr
                  id="appointment-end-date"
                  v-model="appointmentEnd"
                  class="form-control"
                  :config="flatPickrConfig"
                  :class="{
                    'is-invalid': v$.appointmentEnd.$error,
                  }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.appointmentEnd.required.$invalid">
                    Você deve informar uma data de término
                  </span>
                  <span v-else-if="v$.appointmentEnd.valid.$invalid">
                    Você deve informar uma data superior a de início
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group
                label="Endereço presencial / link online"
                label-for="appointment-room-link"
              >
                <b-form-input
                  id="appointment-room-link"
                  v-model="roomLink"
                  :class="{
                    'is-invalid': v$.roomLink.$error,
                  }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.roomLink.required.$invalid">
                    Você deve informar um endereço ou link para reunião
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group
                label="Descrição"
                label-for="appointment-description"
              >
                <b-form-textarea
                  id="appointment-description"
                  v-model="description"
                  :class="{ 'is-invalid': v$.description.$error }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-between">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="hide"
              class="lead-buttons"
            >
              Voltar
            </b-button>
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="lead-buttons"
            >
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormFile,
  BAvatar,
  BButton,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormInput,
  BCol,
  BRow,
  BTooltip,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import { getVueSelectErrorClass } from "@/helpers/validators";
import { stringToDateTime, dateTimeToString } from "@/helpers/converters";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as accountTypes from "@/modules/account/store/types";
import * as appointmentTypes from "@/constants/appointment_types";
import * as customerType from "@/constants/customers_types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BCol,
    BRow,
    vSelect,
    DynamicSelect,
    flatPickr,
    BTooltip,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: {
        customers: false,
        eventTypes: false,
        consultants: false,
        specialistConsultants: false,
      },
      flatPickrConfig: {
        dateFormat: "d/m/Y H:i",
        enableTime: true,
        locale: Portuguese,
        minDate: "today",
      },
      id: undefined,
      customer: undefined,
      customerEmail: undefined,
      eventType: undefined,
      consultant: undefined,
      consultantSpecialist: undefined,
      appointmentStart: undefined,
      appointmentEnd: undefined,
      roomLink: undefined,
      description: undefined,
      saving: false,
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      customer: { required },
      customerEmail: {
        required: requiredIf((value) => {
          if (!this.hasEmail) {
            return true;
          }
          return false;
        }),
      },
      eventType: { required },
      consultant: { required },
      consultantSpecialist: {},
      appointmentStart: { required },
      appointmentEnd: {
        required,
        valid(date) {
          if (this.appointmentStart) {
            const start = moment(this.appointmentStart, "DD/MM/YYYY hh:mm");
            const end = moment(date, "DD/MM/YYYY hh:mm");
            return end > start;
          }
          return true;
        },
      },
      roomLink: { required },
      description: {},
    };
  },
  computed: {
    ...mapGetters({
      newAppointmentSidebar: types.NEW_APPOINTMENT_SIDEBAR,
      eventTypes: types.APPOINTMENT_EVENT_TYPES,
      customers: types.APPOINTMENT_CUSTOMERS,
      consultants: sharedTypes.CONSULTANTS,
      specialistConsultants: sharedTypes.SPECIALIST_CONSULTANTS,
      user: accountTypes.USER,
    }),
    isReschedule: function () {
      return this.newAppointmentSidebar.id && this.newAppointmentSidebar.id > 0;
    },
    isLead() {
      var selected = _.find(this.customers, { id: this.customer });
      if (selected != undefined) {
        if (selected.customer_type_id == customerType.LEAD) {
          return true;
        } else {
          return false;
        }
      }
    },
    isNotLeadPN() {
      var selected = _.find(this.customers, { id: this.customer });
      if (selected != undefined) {
        if (selected.customer_type_id != customerType.LEAD_PN) {
          return true;
        } else {
          return false;
        }
      }
    },
    hasEpRunning() {
      var selected = _.find(this.customers, { id: this.customer });
      if (selected != undefined) {
        if (selected.has_ep_running) {
          return true;
        } else {
          return false;
        }
      }
    },
    hasEmail: function () {
      if (this.customer) {
        var selected = _.find(this.customers, { id: this.customer });
        if (selected && (!selected.email || selected.email === "")) {
          return false;
        }
      }
      return true;
    },
  },
  mounted() {
    this.loading.eventTypes = true;
    this.getEventTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os tipos de compromisso para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.eventTypes = false;
      });

    this.loading.consultants = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultants = false;
      });
    this.loading.specialistConsultants = true;
    this.getSpecialistConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores especialistas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.specialistConsultants = false;
      });
  },
  methods: {
    ...mapMutations({
      mutateNewAppointmentSidebar: types.MUTATE_NEW_APPOINTMENT_SIDEBAR,
      mutateCustomers: types.MUTATE_APPOINTMENT_CUSTOMERS,
    }),
    ...mapActions({
      getAppointment: types.GET_APPOINTMENT,
      getEventTypes: types.GET_APPOINTMENT_EVENT_TYPES,
      getCustomers: types.GET_APPOINTMENT_CUSTOMERS,
      storeAppointment: types.STORE_APPOINTMENT,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getSpecialistConsultants: sharedTypes.GET_SPECIALIST_CONSULTANTS,
      getCustomer: sharedTypes.GET_CUSTOMER,
    }),
    onShow() {
      this.mutateCustomers(undefined);
      this.consultant = this.user?.appify_consultant_id;
      if (this.isReschedule) {
        this.getAppointment(this.newAppointmentSidebar.id)
          .then((resp) => {
            var data = resp.data;
            if (data) {
              this.id = data.id;
              const customer = {
                id: data.customer_id,
                name: data.customer_name,
                email: data.customer_email,
              };
              this.mutateCustomers([customer]);
              this.customer = data.customer_id;
              this.eventType = data.event_type;
              this.consultant = data.consultant_id;
              this.consultantSpecialist = data.consultant_specialist_id;
              this.appointmentStart = dateTimeToString(data.appointment_start);
              this.appointmentEnd = dateTimeToString(data.appointment_end);
              this.roomLink = data.meeting_room_link;
              this.description = data.description;
              this.v$.$touch();
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar o compromisso para edição. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
      if (this.newAppointmentSidebar.customer_id) {
        this.getCustomer(this.newAppointmentSidebar.customer_id)
          .then((resp) => {
            if (resp.data) {
              this.mutateCustomers([resp.data]);
              this.customer = resp.data.id;
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar o cliente. Tente novamente ou realize uma busca pelo nome.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const {
        id,
        customer,
        customerEmail,
        eventType,
        consultant,
        consultantSpecialist,
        appointmentStart,
        appointmentEnd,
        roomLink,
        description,
      } = this;

      this.storeAppointment({
        id,
        customer_id: customer,
        customer_email: customerEmail,
        event_type: eventType,
        consultant_id: consultant,
        consultant_specialist_id: consultantSpecialist,
        appointment_start: stringToDateTime(appointmentStart),
        appointment_end: stringToDateTime(appointmentEnd),
        meeting_room_link: roomLink,
        description,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Compromisso salvo com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateNewAppointmentSidebar({ visible: false });
          this.newAppointmentSidebar.saveAction();
        })
        .catch((err) => {
          if (err.response.status == 405) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Não é possivel Salvar. " + err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao salvar o compromisso. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    findCustomers(keyword) {
      this.loading.customers = true;
      this.getCustomers({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.customers = false;
        });
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    disableItem(option) {
      const selected = _.find(this.customers, { id: this.customer });
      if (!selected) {
        return false;
      }

      if (selected.customer_type_id === customerType.LEAD_PN) {
        return option.id === appointmentTypes.PN_INTERVIEW;
      } else {
        if (selected.has_ep_running) {
          return option.id === appointmentTypes.X_MOMENT;
        } else {
          if (option.id === appointmentTypes.PN_INTERVIEW) {
            return false;
          }
          if (selected.customer_type_id === customerType.LEAD && option.id === appointmentTypes.X_MOMENT) {
            return false;
          }
          return true;
        }
      }
    },
    setEndDate() {
      if (this.appointmentStart) {
        var time = moment(this.appointmentStart, "DD/MM/YYYY hh:mm").add(
          2,
          "h"
        );
        this.appointmentEnd = time.toDate();
      }
    },
    clear() {
      this.customer = undefined;
      this.customerEmail = undefined;
      this.eventType = undefined;
      this.consultant = undefined;
      this.consultantSpecialist = undefined;
      this.appointmentStart = undefined;
      this.appointmentEnd = undefined;
      this.roomLink = undefined;
      this.description = undefined;
      this.id = undefined;
      this.mutateCustomers(undefined);
      this.mutateNewAppointmentSidebar({ customer_id: undefined });
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.sidebar-xg {
  width: 36rem;
}
.lead-buttons {
  width: 10rem;
}
</style>
