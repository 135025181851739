var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-new-lead","sidebar-class":"sidebar-lg","visible":_vm.newLeadSidebar.visible,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"shown":_vm.onShow,"change":function (val) { return _vm.mutateNewLeadSidebar({ visible: val }); },"hidden":_vm.clear},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.newLeadSidebar.id && _vm.isPotentialPartner)?_c('h4',[_vm._v("Editar lead PN")]):(_vm.newLeadSidebar.id && !_vm.isPotentialPartner)?_c('h4',[_vm._v("Editar lead cliente")]):(!_vm.newLeadSidebar.id && _vm.isPotentialPartner)?_c('h4',[_vm._v("Novo lead PN")]):_c('h4',[_vm._v("Novo lead cliente")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"lead-name"}},[_c('b-form-input',{class:{ 'is-invalid': _vm.v$.name.$error },attrs:{"id":"lead-name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"lead-email"}},[_c('b-form-input',{class:{ 'is-invalid': _vm.v$.email.$error || _vm.duplicateEmail || _vm.emailTakenByConsultant },style:(_vm.v$.cellPhone.$error || _vm.duplicateEmail || _vm.emailTakenByConsultant
                    ? 'border-color: #ea5455 !important;'
                    : ''),attrs:{"id":"lead-email","type":"email"},on:{"blur":function($event){return _vm.checkEmail(_vm.email)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.duplicateEmail)?_c('span',[_vm._v(" E-mail duplicado ")]):_vm._e()]),_c('div',{staticClass:"invalid-feedback"},[(_vm.emailTakenByConsultant)?_c('span',[_vm._v(" E-mail utilizado por um consultor ")]):_vm._e()])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Celular","label-for":"lead-cellPhone"}},[_c('vue-tel-input',{class:{
                  'is-invalid': _vm.v$.cellPhone.$error || _vm.duplicatePhone,
                },style:(_vm.v$.cellPhone.$error || _vm.duplicatePhone
                    ? 'border-color: #ea5455 !important;'
                    : ''),attrs:{"id":"lead-cellPhone"},on:{"blur":function($event){return _vm.checkCellPhone(_vm.cellPhone)}},model:{value:(_vm.cellPhone),callback:function ($$v) {_vm.cellPhone=$$v},expression:"cellPhone"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.v$.cellPhone.$invalid)?_c('span',[_vm._v(" Você deve informar um celular válido ")]):_vm._e()]),_c('div',{staticClass:"invalid-feedback"},[(_vm.duplicatePhone)?_c('span',[_vm._v(" Celular já registrado ")]):_vm._e()])],1)],1)],1),(!_vm.isPotentialPartner)?_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Objetivo","label-for":"lead-goal"}},[_c('v-select',{attrs:{"id":"lead-goal","reduce":function (lead_goal) { return lead_goal.key; },"options":_vm.goals,"loading":_vm.loading.goals,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                    var search = ref.search;
                    var searching = ref.searching;
                    var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.goal),callback:function ($$v) {_vm.goal=$$v},expression:"goal"}})],1)],1)],1):_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Formação","label-for":"lead-school-level"}},[_c('v-select',{attrs:{"id":"lead-school-level","reduce":function (school_level) { return school_level.id; },"options":_vm.schoolLevels,"loading":_vm.loading.schoolLevels,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                    var search = ref.search;
                    var searching = ref.searching;
                    var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.schoolLevel),callback:function ($$v) {_vm.schoolLevel=$$v},expression:"schoolLevel"}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Objetivo","label-for":"lead-goal"}},[_c('v-select',{attrs:{"id":"lead-goal","reduce":function (lead_goal) { return lead_goal.key; },"options":_vm.goals,"loading":_vm.loading.goals,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                    var search = ref.search;
                    var searching = ref.searching;
                    var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.goal),callback:function ($$v) {_vm.goal=$$v},expression:"goal"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Estado","label-for":"lead-address-state"}},[_c('v-select',{attrs:{"id":"lead-address-state","reduce":function (address_state) { return address_state.id; },"options":_vm.addressStates,"loading":_vm.loading.addressStates,"label":"name"},on:{"input":_vm.addressStateChanged},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                    var search = ref.search;
                    var searching = ref.searching;
                    var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Cidade","label-for":"lead-address-cities"}},[_c('v-select',{attrs:{"id":"lead-address-cities","reduce":function (address_city) { return address_city.id; },"options":_vm.addressCities,"loading":_vm.loading.addressCities,"disabled":!_vm.state,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                    var search = ref.search;
                    var searching = ref.searching;
                    var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":_vm.origin !== _vm.ownContactOrigin ? 6 : 12}},[_c('b-form-group',{attrs:{"label":"Origem","label-for":"lead-origin"}},[_c('v-select',{attrs:{"id":"lead-origin","reduce":function (lead_origin) { return lead_origin.key; },"options":_vm.origins,"loading":_vm.loading.origins,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                    var search = ref.search;
                    var searching = ref.searching;
                    var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.origin),callback:function ($$v) {_vm.origin=$$v},expression:"origin"}})],1)],1),(_vm.origin !== _vm.ownContactOrigin)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"6"}},[_c('dynamic-select',{class:_vm.getSelectErrorClass(_vm.v$.recommendedBy.$error),attrs:{"id":"lead-recommendedBy","label":"Recomendado por","placeholder":"Digite o nome do cliente","disabled":!_vm.isCustomerRecommendation,"options":_vm.customers,"loading":_vm.loading.recommendedBy},on:{"find":_vm.findCustomers},model:{value:(_vm.recommendedBy),callback:function ($$v) {_vm.recommendedBy=$$v},expression:"recommendedBy"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.v$.recommendedBy.$invalid)?_c('span',[_vm._v(" Você deve informar o cliente que recomendou ")]):_vm._e()])],1):_vm._e()],1),(!_vm.isPotentialPartner)?_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Profissão","label-for":"lead-occupation"}},[_c('v-select',{attrs:{"id":"lead-occupation","reduce":function (lead_occupation) { return lead_occupation.id; },"options":_vm.occupations,"loading":_vm.loading.occupations,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                    var search = ref.search;
                    var searching = ref.searching;
                    var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.occupation),callback:function ($$v) {_vm.occupation=$$v},expression:"occupation"}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Expectativa de renda","label-for":"lead-income"}},[_c('v-select',{attrs:{"id":"lead-income","reduce":function (lead_income) { return lead_income.id; },"options":_vm.incomes,"loading":_vm.loading.incomes,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                    var search = ref.search;
                    var searching = ref.searching;
                    var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.income),callback:function ($$v) {_vm.income=$$v},expression:"income"}})],1)],1)],1):_vm._e(),(_vm.isPotentialPartner)?_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Já Empreendeu?","label-for":"lead-was-entrepreneur"}},[_c('v-select',{attrs:{"id":"lead-was-entrepreneur","reduce":function (option) { return option.key; },"options":[
                  { key: 1, name: 'Sim' },
                  { key: 0, name: 'Não' } ],"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
                var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.was_entrepreneur),callback:function ($$v) {_vm.was_entrepreneur=$$v},expression:"was_entrepreneur"}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Potencial","label-for":"lead-potential"}},[_c('v-select',{attrs:{"id":"lead-potential","reduce":function (leadPotential) { return leadPotential.key; },"options":_vm.leadPotentials,"loading":_vm.loading.leadPotentials,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
                var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.leadPotential),callback:function ($$v) {_vm.leadPotential=$$v},expression:"leadPotential"}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Currículo (apenas PDF)","label-for":"id"}},[(_vm.resumeUrl && !_vm.changeResumeFile)?_c('div',{attrs:{"id":"resume-file-url"}},[_c('h5',[_c('a',{attrs:{"target":"_blank"},on:{"click":_vm.resumeDownload}},[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm._f("onlyFileName")(_vm.resumeUrl))+" ")])])]),_c('b-button',{attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){$event.preventDefault();_vm.changeResumeFile = true}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v("Alterar aquivo")])],1)],1):_c('div',{staticClass:"custom-file b-form-file",on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('input',{ref:"file",staticClass:"custom-file-input",staticStyle:{"z-index":"-5"},attrs:{"id":"resume-file-input","custom-file-label":"Buscar","type":"file","accept":"application/pdf"},on:{"input":_vm.selectedResumeFile}}),_c('label',{staticClass:"custom-file-label",attrs:{"data-browse":"Browse"}},[(!_vm.resumeFile)?_c('span',[_vm._v("Anexar documento...")]):_c('span',[_vm._v(_vm._s(_vm.resumeFile.name))])])])])],1)],1):_vm._e(),(!_vm.isPotentialPartner && _vm.isAdm)?_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Franquia","label-for":"physicalFranchise"}},[_c('v-select',{attrs:{"id":"physicalFranchise","reduce":function (physical_franchise_list) { return physical_franchise_list.id; },"options":_vm.physicalFranchises,"loading":_vm.loading.physicalFranchises,"close-on-select":false,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
                var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.physicalFranchise),callback:function ($$v) {_vm.physicalFranchise=$$v},expression:"physicalFranchise"}})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Observação","label-for":"lead-comment"}},[_c('b-form-textarea',{class:{ 'is-invalid': _vm.v$.comment.$error },attrs:{"id":"lead-comment"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2 justify-content-between"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"lead-buttons",attrs:{"disabled":_vm.saving,"variant":"outline-primary"},on:{"click":hide}},[_vm._v(" Voltar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"lead-buttons",attrs:{"disabled":_vm.saving,"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.saving ? "Salvando..." : "Salvar")+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }